import React, { Component, setGlobal } from "reactn";
import * as Ion from "@ionic/react";
import "./StyleCard.css";
import {
  caretForward,
  arrowBack,
  cart,
  trashBin,
  list,
  grid,
  person,
  menu,
  chevronBack,
  checkmark,
  caretBack,
  caretForwardCircle,
} from "ionicons/icons";
import { ConvertFormat } from "../functions/General";
import * as lod from "lodash";
import {
  Save_Value,
  Save_LiveData,
  ReCalTrans,
  RecLineValue,
} from "../functions/Data";
import PosSettlement from "./PosSettlement";
import PosOrder from "./PosOrder";
import PosStart from "./PosStart";
import { xorWith } from "lodash";
import swal from "sweetalert";

let _textSize = {
  "font-size": "14px",
};

let _lineTextFont = {
  "font-size": "14px",
  color: "Dark",
};

let _lineFont = {
  "font-size": "14px",
  color: "Gray",
};

let _lineBoldFont = {
  "font-size": "14px",
  color: "Red",
  "font-weight": "bold",
};

let ModifierList = [];

let _SelectedClassCode = null;
let _SelectedClassType = null;

export default class PosMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      IsLimit: false,
      IsOpenOutlet: false,
      IsEditAddress: false,
      IsErrorAlert: false,
      IsLoading: false,
      IsComfirmExit: false,
      IsStart: false,
      IsShowOrder: false,
      IsSubmitPayment: false,
      IsEdit: false,
      IsLine: "full",
      ClassData: null,
      ClassGroupData: null,
      ItemData: null,
      IsConfirmPopup: false,
      IsItemPopup: false,
      IsCondimentPopup: false,
      IsModifierPopup: false,
      IsClassGroupPopup: false,
      SelectedItemData: null,
      SelectedCondimentData: null,
      PreSelectedCondimentData: null,
      SelectedCondimentRowIndex: 0,
      SelectedModifierRowIndex: 0,
      SelectedModifierData: null,
      PreSelectedModifierData: null,
      StartClassType: null,
      StartClassCode: null,
      ModifierText: null,
      CondimentText: null,
      Remark: null,
      IsRemark: false,
      ModifierList: [],
      TableName:
        this.global.LiveServiceTypeName + " (" + this.global.TableName + ")",
      //TableName2: '(' + this.global.LanguagePack[this.global.LanguageNo].LabelTable._Data1.TableName + this.global.TableName + ')',
      //TableName2: this.global.LanguagePack[this.global.LanguageNo].LabelTable._Data1.TableName,
      Qty: 1,
    };
  }

  componentDidMount() {
    //console.log('Loading...')

    this._FetchClassGroup();

    //    console.log('Global',this.global)
  }

  _ValidateLiveCount = () => {
    if (this.global.LiveItemCount === 0) {
      this.setState({ IsStart: true, IsConfirmExit: false });
    } else {
      this.setState({ IsConfirmExit: true, IsStart: false });
    }
  };

  _RenderHeader() {
    return (
      <Ion.IonHeader>
        <Ion.IonToolbar color={this.global.Color}>
          <Ion.IonButtons slot="start">
            <Ion.IonButton onClick={() => this._ValidateLiveCount()}>
              <Ion.IonIcon icon={chevronBack}></Ion.IonIcon>
            </Ion.IonButton>
          </Ion.IonButtons>

          <Ion.IonTitle>{this.global.Title}</Ion.IonTitle>

          <Ion.IonButtons slot="end">
            <Ion.IonButton
              onClick={() => this.setState({ IsConfirmPopup: true })}
            >
              <Ion.IonIcon icon={cart} />
              {this._DisplayBadge()}
            </Ion.IonButton>
          </Ion.IonButtons>
        </Ion.IonToolbar>
        {this._RenderGroup()}
        {this._RenderToolbar()}
      </Ion.IonHeader>
    );
  }

  _RenderFooter() {
    return (
      <Ion.IonFooter>
        <Ion.IonSegment>
          <Ion.IonSegmentButton
            value="menu"
            layout="icon-end"
            onClick={() => this._UpdateViewClick("MENU")}
          >
            {" "}
            <Ion.IonIcon icon={menu} />
          </Ion.IonSegmentButton>

          <Ion.IonSegmentButton
            value="person"
            layout="icon-start"
            onClick={() => this.setState({ IsShowOrder: true })}
          >
            <Ion.IonIcon icon={person} />
          </Ion.IonSegmentButton>
        </Ion.IonSegment>
      </Ion.IonFooter>
    );
  }

  _RenderGroup() {
    let _Def = {
      height: "80px",
    };

    if (this.state.ClassGroupData === null) {
      return null;
    }

    //  console.log('Class Group',this.state.ClassGroupData)

    let data = this.state.ClassGroupData;
    return (
      <Ion.IonSegment color={this.global.Color} scrollable>
        {data.map((s) => (
          <Ion.IonSegmentButton onClick={() => this._GroupClick(s.Code)}>
            <Ion.IonLabel>{s.Name}</Ion.IonLabel>
          </Ion.IonSegmentButton>
        ))}
      </Ion.IonSegment>
    );
  }

  _DisplayBadge = () => {
    if (this.global.LiveItemCount === 0) {
      return null;
    } else {
      return (
        <Ion.IonBadge color="danger">{this.global.LiveItemCount}</Ion.IonBadge>
      );
    }
  };
  _RenderToolbar() {
    let _Def = {
      height: "80px",
    };

    let _DefCard22 = {
      //width: '100%',
      width: "170px",
      height: "120px",
      "border-style": "ridge",
    };

    let _DefCard = {
      width: "100%",
      //  width: '570px',
      height: "50px",
      // 'border-style': 'ridge'
    };

    let _lineFontTitle = {
      "font-size": "14px",
    };

    if (this.state.ClassData === null) {
      return null;
    }
    let data = this.state.ClassData;

    //    console.log(data)
    return (
      <div>
        <Ion.IonToolbar>
          <Ion.IonTitle color="dark" style={_lineFontTitle}>
            {
              this.global.LanguagePack[this.global.LanguageNo]
                .ButtonSelectCategory
            }
          </Ion.IonTitle>
        </Ion.IonToolbar>

        <Ion.IonSegment color={this.global.Color} scrollable>
          {data.map((s) => (
            <Ion.IonButton
              color="light"
              style={_DefCard}
              layout="icon-end"
              expand="block"
              fill="outline"
              onClick={() => this._FetchItemDetails(s.ClassType, s.Code)}
            >
              <Ion.IonThumbnail>
                <Ion.IonImg
                  src={s.ImageName + "?" + Math.random()}
                ></Ion.IonImg>
              </Ion.IonThumbnail>
              <Ion.IonLabel className="ion-text-center">
                <Ion.IonText color="dark" className="ion-text-wrap">
                  <h6> &nbsp; {s.Name}</h6>
                </Ion.IonText>
              </Ion.IonLabel>
            </Ion.IonButton>
          ))}
        </Ion.IonSegment>
      </div>
    );
  }

  _SelectOutetInfo = (x) => {
    let _val = {
      OutletCode: x.Code,
      OutletName: x.Name,
    };
    lod.set(this.global.DefaultAppInfo, "SelectedOutletCode", x.Code);
    lod.set(this.global.DefaultAppInfo, "SelectedOutletName", x.Name);
    lod.set(this.global.DefaultAppInfo, "SelectedOutletAddress", x.Address);
    this.setState({
      IsConfirmPopup: true,
      IsOpenOutlet: false,
      SelectedOutletInfo: _val,
    });
  };

  _RenderOutlet = () => {
    let _Def = {
      height: "180px",
      width: "100%",
    };

    if (this.global.SelectedOutletData === null) {
      return null;
    }

    let _data = this.global.SelectedOutletData;
    return (
      <Ion.IonModal isOpen={this.state.IsOpenOutlet} backdropDismiss={false}>
        <Ion.IonHeader>
          <Ion.IonToolbar color={this.global.Color}>
            <Ion.IonButtons slot="start">
              <Ion.IonButton
                onClick={() => this.setState({ IsOpenOutlet: false })}
              >
                <Ion.IonIcon icon={chevronBack}></Ion.IonIcon>
              </Ion.IonButton>
            </Ion.IonButtons>
            <Ion.IonTitle></Ion.IonTitle>
          </Ion.IonToolbar>
        </Ion.IonHeader>
        <Ion.IonContent>
          <Ion.IonList>
            <Ion.IonListHeader>
              <Ion.IonText>Select Outlet</Ion.IonText>
            </Ion.IonListHeader>
            <br />

            {_data.map((item) => (
              <Ion.IonItem onClick={() => this._SelectOutetInfo(item)}>
                <Ion.IonThumbnail slot="start">
                  <Ion.IonImg
                    src={item.ImageName}
                    alt=""
                    style={_Def}
                    className="MyList"
                  ></Ion.IonImg>
                </Ion.IonThumbnail>
                <Ion.IonLabel>
                  {item.Name}
                  <br />
                  <Ion.IonText className="ion-text-wrap">
                    {item.Address}
                  </Ion.IonText>
                </Ion.IonLabel>
              </Ion.IonItem>
            ))}
          </Ion.IonList>
        </Ion.IonContent>
      </Ion.IonModal>
    );
  };

  _FetchMaster = async () => {
    //this.global.LiveServiceType

    let _SerCode = localStorage.getItem("DefServiceCode");

    let _url =
      this.global.BaseUrl +
      "Item/GetMaster?zMasterOutletCode=" +
      this.global.ProfileCode +
      "&zLanguageNo=" +
      this.global.LanguageNo +
      "&zServiceCode=" +
      _SerCode +
      "&zTokenID=" +
      this.global.Token +
      "&zTableCode=" +
      this.global.TableCode;

    console.log("Fetch Master");

    await fetch(_url)
      .then((response) => response.json())
      .then((result) => {
        //  console.log('Hello',result)
        this.setGlobal({
          ItemMaster: result.ItemMaster,
          ClassMaster: result.ClassMaster,
        });
      });
  };

  _FetchClassGroup = () => {
    let _ClassGroup = this.global.ClassMaster;

    //      console.log('Code Group', _ClassGroup)

    if (_ClassGroup === null) {
      //    this._FetchMaster()
      //    console.log('Code Group', _ClassGroup)
    }

    let _Code = _ClassGroup[0].AClassCode;
    let _Type = _ClassGroup[0].AClassType;

    //   console.log('Code Code', _Code)

    let _item = lod.filter(
      this.global.ItemMaster,
      (c) => c.ClassType === _Type && c.ClassCode === _Code
    );

    let _data = lod.filter(this.global.ClassMaster, (c) => c.Code === _Type);

    //    console.log('Item Details',_item)
    //   console.log('Class Details',_data)
    this.setState({
      IsLoading: false,
      ClassGroupData: _ClassGroup,
      StartClassType: _Type,
      StartClassCode: _Code,
      ItemData: _item,
      ClassData: _data[0].Details,
    });
  };

  _FetchClassDetails = async (zClassType) => {
    let _data = lod.filter(
      this.global.ClassMaster,
      (c) => c.Code === zClassType
    );
    this.setState({ ClassData: _data });
  };

  _FetchItemDetails = async (zClassType, zClassCode) => {
    let _data = lod.filter(
      this.global.ItemMaster,
      (c) => c.ClassType === zClassType && c.ClassCode === zClassCode
    );

    if (_data.length === 0) {
      this.setState({ IsErrorAlert: true, IsLoading: false, ItemData: [] });
    } else {
      this.setState({ IsErrorAlert: false, IsLoading: false, ItemData: _data });
    }
  };

  _ItemClick = (zCode) => {
    let data = lod.filter(this.state.ItemData, (c) => c.Code === zCode);
    this.setState({ IsItemPopup: true, SelectedItemData: data });
  };

  _GroupClick = (zCode) => {
    let data1 = lod.filter(this.state.ClassGroupData, (c) => c.Code === zCode);
    let data = data1[0].Details;

    //   console.log('Group Click----1111>', data)

    let _Code = data[0].Code;
    let _Type = data[0].ClassType;

    //    console.log('Group Click---->', _Code)

    let _item = lod.filter(
      this.global.ItemMaster,
      (c) => c.ClassType === _Type && c.ClassCode === _Code
    );

    //console.log('Iteming',_item)

    this.setState({ ClassData: data, ItemData: _item });

    //   this.setState({ IsClassGroupPopup: false, StartClassType: data[0].Code, StartClassCode: data[0].AClassCode })

    //  this.forceUpdate()
  };

  _RenderItem = () => {
    if (this.state.ItemData === null) {
      //   console.log('No Item')
      return null;
    }

    let data = this.state.ItemData;

    let _Def = {
      "padding-top": "0px",
      height: "180px",
      width: "100%",
      // 'object-fit': 'cover'
    };

    let _DefDiv = {
      height: "50px",
      "padding-top": "10px",
    };

    let _But = {
      "padding-top": "10px",
      "padding-bottom": "10px",
      height: "30px",
    };

    return (
      <Ion.IonInfiniteScroll threshold="100px">
        <Ion.IonGrid>
          <Ion.IonRow>
            {data.map((item) => (
              //  <ion-col size="12" size-xs="12" size-sm="6" size-md="4" size-lg="3" >
              <Ion.IonCol
                size-lg="2"
                size-xl="2"
                size-md="2"
                size-sm="4"
                size="6"
              >
                <Ion.IonCard
                  className="MyCard"
                  onClick={() => this._ItemClick(item.Code)}
                >
                  <div className="ImageContainer">
                    <Ion.IonImg
                      loading="eager"
                      src={item.ImageName}
                      alt=""
                      style={_Def}
                      className="MyImage"
                    />
                    {/*   <Ion.IonLabel className="ion-text-center">
                                            <Ion.IonText className="ion-text-wrap">
                                                <div className="ImageTextBottom">
                                                    <h6>{ConvertFormat(item.PosPrice)}</h6>
                                                </div>
                                            </Ion.IonText>
                                        </Ion.IonLabel> */}
                  </div>
                </Ion.IonCard>
                <div style={_DefDiv}>
                  <Ion.IonLabel className="ion-text-center">
                    <Ion.IonText className="ion-text-wrap">
                      <h6>
                        {item.Code} - {item.Name}
                      </h6>
                      <h6 style={_lineBoldFont}>
                        RM {ConvertFormat(item.PosPrice)}
                      </h6>
                    </Ion.IonText>
                  </Ion.IonLabel>
                </div>
                {/*  <Ion.IonFooter>
                                    <Ion.IonButton
                                        color={this.global.Color}
                                        expand="block"
                                        onClick={() => this._ItemClick(item.Code)}>
                                        {this.global.LanguagePack[this.global.LanguageNo].ButtonAddCaption}
                                    </Ion.IonButton>
                                </Ion.IonFooter> */}
              </Ion.IonCol>
            ))}
          </Ion.IonRow>
        </Ion.IonGrid>
      </Ion.IonInfiniteScroll>
    );
  };

  _ButtonQty = (e) => {
    if (this.global.IsNoEditQty === true) {
      return null;
    }

    let x = this.state.Qty;
    if (e === "+") {
      x = x + 1;
      this.setState({ Qty: x });
    } else {
      x = x - 1;
      if (x === 0) {
        x = 1;
      }
      this.setState({ Qty: x });
    }
  };

  _RenderRemark = () => {
    return (
      <Ion.IonModal isOpen={this.state.IsRemark} backdropDismiss={false}>
        <Ion.IonHeader>
          <Ion.IonToolbar color={this.global.Color}>
            <Ion.IonButtons slot="start">
              <Ion.IonButton onClick={() => this.setState({ IsRemark: false })}>
                <Ion.IonIcon icon={chevronBack}></Ion.IonIcon>
              </Ion.IonButton>
            </Ion.IonButtons>
            <Ion.IonButtons slot="end">
              <Ion.IonButton onClick={() => this.setState({ IsRemark: false })}>
                <Ion.IonIcon icon={checkmark}></Ion.IonIcon>
              </Ion.IonButton>
            </Ion.IonButtons>
            <Ion.IonTitle>
              {" "}
              {
                this.global.LanguagePack[this.global.LanguageNo]
                  .ButtonRemarkCaption
              }
            </Ion.IonTitle>
          </Ion.IonToolbar>
        </Ion.IonHeader>
        <Ion.IonContent fullscreen>
          <Ion.IonItem>
            <Ion.IonInput
              placeholder="Enter Remark"
              onIonChange={(e) => this.setState({ Remark: e.target.value })}
              clearInput
            ></Ion.IonInput>
          </Ion.IonItem>
        </Ion.IonContent>
      </Ion.IonModal>
    );
  };

  _RenderRmk = () => {
    return (
      <div>
        <Ion.IonButtons slot="end">
          <Ion.IonButton onClick={() => this.setState({ IsRemark: true })}>
            {
              this.global.LanguagePack[this.global.LanguageNo]
                .ButtonRemarkCaption
            }
          </Ion.IonButton>
        </Ion.IonButtons>
      </div>
    );
  };

  _RenderSelectedItem = () => {
    if (this.state.SelectedItemData === null) {
      return null;
    }

    ///  console.log("ddddddddd", this.global.LiveData);
    let _Def = {
      height: "200px",
      width: "100%",
      "object-fit": "contain",
    };
    let _SizeButton = {
      width: "100%",
    };
    let _ModalDef = {
      position: "absolute",
      height: "150px",
      width: "300px",
      display: "block",
    };

    let data = this.state.SelectedItemData[0];

    let _Total = this.state.Qty * data.PosPrice;

    //   console.log(data)
    return (
      <Ion.IonModal isOpen={this.state.IsItemPopup} backdropDismiss={false}>
        <Ion.IonHeader>
          <Ion.IonToolbar color={this.global.Color}>
            <Ion.IonButtons slot="start">
              <Ion.IonButton onClick={() => this._ResetLineItem()}>
                <Ion.IonIcon icon={chevronBack}></Ion.IonIcon>
              </Ion.IonButton>
            </Ion.IonButtons>
            <Ion.IonTitle>
              {data.Code} - {data.Name}
            </Ion.IonTitle>
          </Ion.IonToolbar>
        </Ion.IonHeader>
        <Ion.IonContent fullscreen>
          <Ion.IonItemGroup>
            <div className="ImageContainer">
              <img
                loading="eager"
                src={data.ImageName2X + "?" + Math.random()}
                alt=""
                style={_Def}
                className="MyImage"
              />
              {/* <div className="ImageText">
                                <Ion.IonLabel className="ion-text-center">
                                    <Ion.IonText className="ion-text-wrap">
                                        <h6> {data.Name}</h6>
                                    </Ion.IonText>
                                </Ion.IonLabel>
                            </div> */}
            </div>

            <Ion.IonGrid>
              <Ion.IonRow>
                <Ion.IonCol>
                  <Ion.IonButton
                    color={this.global.Color}
                    shape="round"
                    fill="outline"
                    style={_SizeButton}
                    onClick={() => this._ButtonQty("-")}
                  >
                    -
                  </Ion.IonButton>
                </Ion.IonCol>
                <Ion.IonCol>
                  <Ion.IonButton color={this.global.Color} style={_SizeButton}>
                    {this.state.Qty}
                  </Ion.IonButton>
                </Ion.IonCol>
                <Ion.IonCol>
                  <Ion.IonButton
                    color={this.global.Color}
                    shape="round"
                    fill="outline"
                    style={_SizeButton}
                    onClick={() => this._ButtonQty("+")}
                  >
                    +
                  </Ion.IonButton>
                </Ion.IonCol>
              </Ion.IonRow>
            </Ion.IonGrid>
            <Ion.IonItem lines="none">
              <Ion.IonLabel className="ion-text-wrap" slot="start">
                <Ion.IonText>
                  {data.Code} - {data.Name}
                </Ion.IonText>
              </Ion.IonLabel>
              <Ion.IonLabel slot="end">
                <Ion.IonText color="danger">
                  {ConvertFormat(_Total)}
                </Ion.IonText>
              </Ion.IonLabel>
            </Ion.IonItem>
            {this._ShowSelectedRemark()}
            {this._ShowCaption(data.MenuCaption)}
            {this._ShowModifierText()}
          </Ion.IonItemGroup>

          {this._RenderCondimentButton()}
          {this._RenderModifierButton()}
        </Ion.IonContent>

        <Ion.IonFooter>
          <Ion.IonButton
            color={this.global.Color}
            expand="full"
            size="large"
            onClick={() => this._SaveCart()}
          >
            {this.global.LanguagePack[this.global.LanguageNo].ButtonAddCart}
          </Ion.IonButton>
        </Ion.IonFooter>
      </Ion.IonModal>
    );
  };

  _ShowModifierText = (x) => {
    let _data = ModifierList;

    //    console.log('Modifier List', _data)

    if (_data.length === 0) {
      return null;
    }

    return (
      <Ion.IonText color={this.global.Color} style={_textSize}>
        <Ion.IonList>
          {_data.map((c, index) => (
            <div>
              <span className="MyDisplayLeft">{c.Name}</span>
              <span className="MyDisplayRight">
                {" "}
                RM {ConvertFormat(c.Price)}
              </span>
              <br />
            </div>
          ))}
        </Ion.IonList>
      </Ion.IonText>
    );
  };

  _ShowCaption = (e) => {
    if (e === "") {
      return null;
    }

    return (
      <Ion.IonItem lines="none">
        <Ion.IonLabel className="ion-text-wrap">
          <Ion.IonText>
            <h6>{e}</h6>
          </Ion.IonText>
        </Ion.IonLabel>
      </Ion.IonItem>
    );
  };

  _ShowSelectedRemark = () => {
    if (this.state.Remark === "null") {
      return null;
    }

    return (
      <Ion.IonItem lines="none">
        <Ion.IonLabel className="ion-text-wrap">
          <Ion.IonText>
            <h6>{this.state.Remark}</h6>
          </Ion.IonText>
        </Ion.IonLabel>
      </Ion.IonItem>
    );
  };

  _AlertConfirm = () => {
    return (
      <Ion.IonAlert
        isOpen={this.state.IsConfirm}
        onDidDismiss={() => this.setState({ IsConfirm: false })}
        message={this.global.LanguagePack[this.global.LanguageNo].AlertQuestion}
        header={
          this.global.LanguagePack[this.global.LanguageNo].AlertQuestionTitle
        }
        buttons={[
          {
            text: this.global.LanguagePack[this.global.LanguageNo]
              .ButtonCancelCaption,
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => () => this.setState({ IsConfirm: false }),
          },
          {
            text: this.global.LanguagePack[this.global.LanguageNo]
              .ButtonComfirmCaption,
            handler: () => this.setState({ IsSubmitPayment: true }),
          },
        ]}
      />
    );
  };

  _AlertConfirmExit = () => {
    return (
      <Ion.IonAlert
        isOpen={this.state.IsConfirmExit}
        onDidDismiss={() => this.setState({ IsConfirmExit: false })}
        header={this.global.LanguagePack[this.global.LanguageNo].AlertTitle}
        message={
          this.global.LanguagePack[this.global.LanguageNo].AlertConfirmBack
        }
        buttons={[
          {
            text: this.global.LanguagePack[this.global.LanguageNo]
              .ButtonCancelCaption,
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => () => this.setState({ IsConfirmExit: false }),
          },
          {
            text: this.global.LanguagePack[this.global.LanguageNo]
              .ButtonComfirmCaption,
            handler: () =>
              this.setState({ IsConfirmExit: false, IsStart: true }),
          },
        ]}
      />
    );
  };

  _RenderClassGroupPopup = () => {
    if (this.state.ClassGroupData === null) {
      return null;
    }

    let _Def = {
      height: "120px",
      width: "100%",
    };

    //  let obj = lod.filter(this.state.AllClassDetailsData, (c) => c.ClassType === e);
    //   let data = lod.filter(this.state.ItemData, (c) => c.Code === zCode)
    let data = this.state.ClassGroupData;

    return (
      <Ion.IonModal
        isOpen={this.state.IsClassGroupPopup}
        backdropDismiss={false}
      >
        <Ion.IonHeader>
          <Ion.IonToolbar color={this.global.Color}>
            <Ion.IonButtons slot="start">
              <Ion.IonButton
                onClick={() => this.setState({ IsClassGroupPopup: false })}
              >
                <Ion.IonIcon icon={arrowBack}></Ion.IonIcon>
              </Ion.IonButton>
            </Ion.IonButtons>
          </Ion.IonToolbar>
        </Ion.IonHeader>

        <Ion.IonContent>
          <Ion.IonList>
            <Ion.IonListHeader>
              <Ion.IonText>
                <h1>
                  {
                    this.global.LanguagePack[this.global.LanguageNo]
                      .LabelSelectClassification
                  }
                </h1>
              </Ion.IonText>
            </Ion.IonListHeader>

            {data.map((item) => (
              <Ion.IonItem onClick={() => this._GroupClick(item.Code)}>
                <Ion.IonThumbnail slot="start">
                  <img
                    loading="eager"
                    src={item.ImageName + "?" + Math.random()}
                    alt=""
                    style={_Def}
                    className="MyList"
                  />
                </Ion.IonThumbnail>
                <Ion.IonLabel>
                  <h3> {item.Name}</h3>
                </Ion.IonLabel>
              </Ion.IonItem>
            ))}
          </Ion.IonList>
        </Ion.IonContent>
      </Ion.IonModal>
    );
  };

  _RenderCondimentButton = () => {
    let data = this.state.SelectedItemData[0].CondimentDetails;

    if (data === null) {
      return null;
    }

    return (
      <div>
        <Ion.IonItem style={_lineFont} color={this.global.Color}>
          <Ion.IonLabel>
            <h6>
              {
                this.global.LanguagePack[this.global.LanguageNo]
                  .ButtonSelectCondiment
              }
            </h6>
          </Ion.IonLabel>
        </Ion.IonItem>

        {/*            <Ion.IonList>
                    {data.map((s, index) => (
                        <Ion.IonItem lines='none'>
                            <Ion.IonTitle color={this.global.Color}>
                                <h2 style={_lineTextFont}>{s.Name}</h2>
                                <p />
                                {this._RenderSubCondimentButton(index)}
                            </Ion.IonTitle>
                        </Ion.IonItem>
                    ))}
                </Ion.IonList>
        */}

        {data.map((s, index) => (
          <div>
            <Ion.IonItem>
              <Ion.IonLabel className="ion-text-center" color="dark">
                <h2 style={_lineTextFont}>{s.Name}</h2>
              </Ion.IonLabel>
            </Ion.IonItem>
            <br />
            {this._RenderSubCondimentButton(index)}
          </div>
        ))}
      </div>
    );
  };

  _RenderSubCondimentButton = (x) => {
    let data = this.state.SelectedItemData[0].CondimentDetails[x]
      .CondimentDetails;

    if (data === null) {
      return null;
    }

    if (data === null) {
      return null;
    }

    let _lineDef = {
      height: "100px",
    };

    return (
      /*            <Ion.IonList color={this.global.Color}>
                           {data.map((c, index) => (
                               <Ion.IonItem lines='full'>
                                   <Ion.IonLabel style={_lineFont}>{c.Name}</Ion.IonLabel>
                                   <Ion.IonCheckbox color={this.global.Color}
                                       checked={c.IsCheck}
                                       slot="start"
                                       onIonChange={(e) => this._UpdateSelectedCheckBox(e, 'CONDIMENT', index, data)}
                                   ></Ion.IonCheckbox>
                               </Ion.IonItem>
                           ))
                           }
                       </Ion.IonList> */

      data.map((c, index) => (
        <div>
          <Ion.IonItem lines="full">
            <Ion.IonText slot="end" color={this.global.Color}>
              <h2 style={_lineTextFont}>{c.Name}</h2>
            </Ion.IonText>

            <Ion.IonCheckbox
              slot="start"
              style={_lineTextFont}
              color={this.global.Color}
              checked={c.IsCheck}
              onIonChange={(e) =>
                this._UpdateSelectedCheckBox(e, "CONDIMENT", index, data)
              }
            ></Ion.IonCheckbox>
            <br />
          </Ion.IonItem>
        </div>
      ))
    );
  };

  _RenderModifierButton = () => {
    let data = this.state.SelectedItemData[0].ModifierDetails;

    if (data === null) {
      return null;
    }
    //console.log("Selected Modifier", data);

    return (
      <div>
        <Ion.IonItem style={_lineFont} color={this.global.Color}>
          <Ion.IonLabel>
            <h6>
              {
                this.global.LanguagePack[this.global.LanguageNo]
                  .ButtonSelectModifier
              }
            </h6>
          </Ion.IonLabel>
        </Ion.IonItem>

        {/*        <Ion.IonList>
                    {data.map((s, index) => (
                        <Ion.IonItem lines='none'>
                            <Ion.IonTitle color={this.global.Color}>
                                <h2 style={_lineTextFont}>{s.Name}</h2>
                                <p />
                                {this._RenderModifierDetails(index)}
                            </Ion.IonTitle>

                        </Ion.IonItem>
                    ))}
                </Ion.IonList> */}

        {data.map((s, index) => (
          <div>
            <Ion.IonItem>
              <Ion.IonLabel className="ion-text-center" color="dark">
                <h2 style={_lineTextFont}>{s.Name}</h2>
              </Ion.IonLabel>
            </Ion.IonItem>
            <br />
            {this._RenderModifierDetails(index)}
          </div>
        ))}
      </div>
    );
  };

  _RenderModifierDetails = (x) => {
    //  let data = this.state.SelectedModifierData
    let data = this.state.SelectedItemData[0].ModifierDetails[x]
      .ModifierDetails;

    if (data === null) {
      return null;
    }

    return (
      /*    <Ion.IonList>
                   {data.map((c, index) => (
                       <Ion.IonItem>
                           <Ion.IonLabel className="ion-text-wrap" style={_lineFont}>{c.Name}</Ion.IonLabel>
                           <Ion.IonLabel className="ion-text-right" style={_lineFont}>
                               {ConvertFormat(c.UnitPrice)}
                           </Ion.IonLabel>
     
                           <Ion.IonCheckbox color={this.global.Color}
                               checked={c.IsCheck}
                               slot="start"
                               onIonChange={(e) => this._UpdateSelectedCheckBox(e, 'MODIFIER', index, data)}
                           ></Ion.IonCheckbox>
                       </Ion.IonItem>
                   ))
                   }
               </Ion.IonList> */

      data.map((c, index) => (
        <div>
          <Ion.IonItem lines="full">
            <Ion.IonLabel className="ion-text-wrap" style={_lineFont}>
              {c.Name}
            </Ion.IonLabel>
            <Ion.IonLabel className="ion-text-right" style={_lineFont}>
              {ConvertFormat(c.UnitPrice)}
            </Ion.IonLabel>

            <Ion.IonCheckbox
              slot="start"
              style={_lineTextFont}
              color={this.global.Color}
              checked={c.IsCheck}
              onIonChange={(e) =>
                this._UpdateSelectedCheckBox(e, "MODIFIER", index, data, x)
              }
            ></Ion.IonCheckbox>
            <br />
          </Ion.IonItem>
        </div>
      ))
    );
  };

  _PopupCondimentDetails = () => {
    let data = this.state.SelectedCondimentData;

    //console.log(this.global)

    if (data === null) {
      return null;
    }

    return (
      <Ion.IonModal
        isOpen={this.state.IsCondimentPopup}
        backdropDismiss={false}
      >
        <Ion.IonHeader>
          <Ion.IonToolbar color={this.global.Color}>
            <Ion.IonButtons slot="start">
              <Ion.IonButton
                onClick={() =>
                  this.setState({
                    IsCondimentPopup: false,
                    SelectedCondimentData: this.state.PreSelectedCondimentData,
                  })
                }
              >
                <Ion.IonIcon icon={chevronBack}></Ion.IonIcon>
              </Ion.IonButton>
            </Ion.IonButtons>
            <Ion.IonTitle></Ion.IonTitle>
          </Ion.IonToolbar>
        </Ion.IonHeader>
        <Ion.IonContent>
          <Ion.IonList>
            {" "}
            {data.map((c, index) => (
              <Ion.IonItem>
                <Ion.IonLabel className="ion-text-wrap">{c.Name}</Ion.IonLabel>
                <Ion.IonLabel className="ion-text-right"></Ion.IonLabel>

                <Ion.IonCheckbox
                  checked={c.IsCheck}
                  slot="start"
                  onIonChange={(e) =>
                    this._UpdateSelectedCheckBox(e, "CONDIMENT", index)
                  }
                ></Ion.IonCheckbox>
              </Ion.IonItem>
            ))}
          </Ion.IonList>
        </Ion.IonContent>
        <Ion.IonFooter>
          <Ion.IonButton
            color={this.global.Color}
            expand="full"
            onClick={() => this._SaveText("CONDIMENT")}
          >
            {this.global.LanguagePack[this.global.LanguageNo].ButtonAddCart}
          </Ion.IonButton>
        </Ion.IonFooter>
      </Ion.IonModal>
    );
  };

  _PopupModifierDetails = () => {
    let data = this.state.SelectedModifierData;

    //  console.log("datadata", data);

    if (data === null) {
      return null;
    }

    return (
      <Ion.IonModal isOpen={this.state.IsModifierPopup} backdropDismiss={false}>
        <Ion.IonHeader>
          <Ion.IonToolbar color={this.global.Color}>
            <Ion.IonButtons slot="start">
              <Ion.IonButton
                onClick={() =>
                  this.setState({
                    IsModifierPopup: false,
                    SelectedModifierData: this.state.PreSelectedModifierData,
                  })
                }
              >
                <Ion.IonIcon icon={chevronBack}></Ion.IonIcon>
              </Ion.IonButton>
            </Ion.IonButtons>
            <Ion.IonTitle></Ion.IonTitle>
          </Ion.IonToolbar>
        </Ion.IonHeader>
        <Ion.IonContent>
          <Ion.IonList>
            {data.map((c, index) => (
              <Ion.IonItem>
                <Ion.IonLabel className="ion-text-wrap">{c.Name}</Ion.IonLabel>
                <Ion.IonLabel className="ion-text-right">
                  RM {ConvertFormat(c.UnitPrice)}
                </Ion.IonLabel>

                <Ion.IonCheckbox
                  checked={c.IsCheck}
                  slot="start"
                  onIonChange={(e) =>
                    this._UpdateSelectedCheckBox(e, "MODIFIER", index)
                  }
                ></Ion.IonCheckbox>
              </Ion.IonItem>
            ))}
          </Ion.IonList>
        </Ion.IonContent>
        <Ion.IonFooter>
          <Ion.IonButton
            color={this.global.Color}
            expand="full"
            onClick={() => this._SaveText("MODIFIER")}
          >
            {this.global.LanguagePack[this.global.LanguageNo].ButtonAddCart}
          </Ion.IonButton>
        </Ion.IonFooter>
      </Ion.IonModal>
    );
  };

  _RenderErrorPopup = () => {
    return (
      <Ion.IonAlert
        backdropDismiss={false}
        isOpen={this.state.IsErrorAlert}
        onDidDismiss={() => this.setState({ IsErrorAlert: false })}
        message="404 Error"
        header={this.global.LanguagePack[this.global.LanguageNo].AlertTitle}
        buttons={["OK"]}
      />
    );
  };

  _UpdateSelectedCheckBox = (e, ViewType, index, zdata, x) => {
    //   console.log("e target", e.target.checked);

    let _IsCheckModifier = 0;
    let _TotalClick = 0;

    let _RefDesc = this.global.RefDesc;

    // Modifier
    if (_RefDesc.substr(13, 1) === "O") {
      _IsCheckModifier = 1;
    }

    switch (ViewType) {
      case "MODIFIER":
        let data1 = this.state.SelectedItemData[0].ModifierDetails[x];

        //   console.log("Modifier....", data1);

        if (data1.ControlQty != 0) {
          if (data1.TotalClick === undefined) {
            _TotalClick = 1;
          } else {
            if (e.target.checked === true) {
              _TotalClick = parseInt(data1.TotalClick) + 1;
            } else {
              //  console.log("False False", data1.TotalClick);
              if (parseInt(data1.TotalClick) === 0) {
                _TotalClick = 0;
              }
              {
                _TotalClick = parseInt(data1.TotalClick) - 1;
              }
            }
          }

          //  console.log("Check Modifier", _IsCheckModifier);
          //  console.log("Total Click", _TotalClick);
          //  console.log("data1 Control", data1.ControlQty);

          if (_IsCheckModifier === 1) {
            if (_TotalClick > data1.ControlQty) {
              //  console.log("Over Limit");
              swal("Over Limit");
              //swal("Hello world!");
              // this.setState({ IsLimit: true });
              lod.set(data1, "TotalClick", _TotalClick);
              e.target.checked = false;
            } else {
              lod.set(data1, "TotalClick", _TotalClick);
              let data = zdata;

              lod.set(data[index], "IsCheck", e.target.checked);
              this.setState({ data });
            }
          } else {
            lod.set(data1, "TotalClick", _TotalClick);
            let data = zdata;
            lod.set(data[index], "IsCheck", e.target.checked);
            this.setState({ data });
          }
        } else {
          //    console.log("xxxx", this.state.SelectedItemData[0].ModifierDetails);

          lod.set(data1, "TotalClick", _TotalClick);
          let data = zdata;
          lod.set(data[index], "IsCheck", e.target.checked);
          this.setState({ data });
        }

        break;

      case "CONDIMENT":
        let data = zdata;
        lod.set(data[index], "IsCheck", e.target.checked);
        this.setState({ data });
      default:
        break;
    }
  };

  _ValidateModifierClick = () => {
    let _Data = this.state.SelectedItemData[0].ModifierDetails;

    //  console.log("Validate", _Data);

    if (_Data === null) {
      return true;
    }

    let i = 0;
    let k = 0;
    let _Total = 0;

    for (i = 0; i < _Data.length; i++) {
      /*  console.log(
        "dddd--->",
        _Data[i].Code,
        _Data[i].ControlQty,
        _Data[i].TotalClick
      ); */

      if (_Data[i].ControlQty != 0) {
        if (_Data[i].ControlQty != _Data[i].TotalClick) {
          swal(
            "Please complete Total " +
              _Data[i].ControlQty +
              " quantity on '" +
              _Data[i].Name +
              "' !!!"
          );
          return false;
        }
      }
    }

    return true;
  };

  _SaveText = (ViewType) => {
    let _Data;
    var i;
    var k;
    let _txt = "";

    if (ViewType === "MODIFIER") {
      _Data = this.state.SelectedItemData[0].ModifierDetails;

      //       console.log('Save Modifier', _Data)

      for (i = 0; i < _Data.length; i++) {
        for (k = 0; k < _Data[i].ModifierDetails.length; k++) {
          let x = _Data[i].ModifierDetails[k];
          if (x.IsCheck === true) {
            _txt = _txt + x.Name + ",";
          }
        }
      }
    } else {
      _Data = this.state.SelectedItemData[0].CondimentDetails;

      if (_Data === null) {
        return null;
      }

      for (i = 0; i < _Data.length; i++) {
        for (k = 0; k < _Data[i].CondimentDetails.length; k++) {
          let x = _Data[i].CondimentDetails[k];
          if (x.IsCheck === true) {
            _txt = _txt + x.Name + ",";
          }
        }
      }
    }

    if (_txt.length >= 1) {
      _txt = _txt.substring(0, _txt.length - 1);
    }

    return _txt;
    /*   if (ViewType === "MODIFIER") {
              this.setState({ ModifierText: _txt, IsModifierPopup: false });
          } else {
              this.setState({ CondimentText: _txt, IsCondimentPopup: false });
          } */
  };

  _RenderSelectedText = (ViewType, row) => {
    let _Data;
    var i;
    var k;
    let _txt = "";
    let _Arr = {};
    let _NewData = [];

    if (ViewType === "MODIFIER") {
      _Data = this.state.SelectedItemData[0].ModifierDetails[row];

      if (_Data === null) {
        return null;
      }

      //    console.log('Save Modifier', _Data)

      for (k = 0; k < _Data.ModifierDetails.length; k++) {
        let x = _Data.ModifierDetails[k];
        if (x.IsCheck === true) {
          _txt = _txt + x.Name + ",";
          _Arr = { Name: x.Name, Price: x.UnitPrice };
          _NewData.push(_Arr);
        }
      }
    } else {
      _Data = this.state.SelectedItemData[0].CondimentDetails[row];

      for (k = 0; k < _Data.CondimentDetails.length; k++) {
        let x = _Data.CondimentDetails[k];
        if (x.IsCheck === true) {
          _txt = _txt + x.Name + ",";
        }
      }
    }

    if (_txt.length >= 1) {
      _txt = _txt.substring(0, _txt.length - 1);
      ModifierList = _NewData;
    }

    return _txt;
  };

  _SaveCart = () => {
    let data = this.state.SelectedItemData[0];

    // let _RefDesc = this.global.RefDesc;
    //  console.log("dddd", _RefDesc);

    //   console.log("Save Validate");

    let _Check = this._ValidateModifier();
    if (_Check === false) {
      return null;
    }

    //   console.log("Save Cart", data);

    let _Qty = this.state.Qty;
    let _UnitPrice = data.PosPrice;
    let _Amount = _Qty * _UnitPrice;
    //let _Cond = this.state.CondimentText;
    let _Cond = this._SaveText("CONDIMENT");
    let _ModifierData = this._GetModifierData();
    let _ComboData = this._GetFixComboData();

    //  console.log("ComboSet", _ComboData);
    //   this._SaveText("CONDIMENT")

    //   console.log("Save Cart");
    //   console.log("This data", this.state.Data);
    //   console.log(_ModifierData);
    //  _StoreValue = (zAction, zIndex, zMenuCode, zMenuName, zQty, zUnitPrice, zAmount, zRemark, zCondiment, zModifier) => {'

    let val = {
      Action: "ADD",
      Index: 0,
      SvcValue: data.SvcRate,
      TaxValue: data.TaxRate,
      SvcRate: data.SvcRate,
      TaxRate: data.TaxRate,
      TaxMethod: data.TaxMethod,
      MenuCode: data.Code,
      MenuName: data.Name,
      Qty: _Qty,
      UnitPrice: _UnitPrice,
      Amount: _Amount,
      Remark: this.state.Remark,
      Condiment: _Cond,
      ModifierData: _ModifierData,
      ComboSetData: _ComboData,
      ModifierList: ModifierList,
      IsTaxSvc: data.IsTaxSvc,
      RvcRefDesc: data.RvcServiceRef,
    };

    //console.log("Save - Data", val);
    Save_LiveData(val, this.global.LiveData);

    ModifierList = [];

    this._ResetLineItem();
    //console.log("Save", this.global);
  };

  _ResetLineItem = () => {
    let _Data = this.state.SelectedItemData[0].ModifierDetails;

    // console.log("Reset", _Data);

    if (_Data != null) {
      let i = 0;
      let k = 0;

      for (i = 0; i < _Data.length; i++) {
        let p = _Data[i];
        lod.set(p, "TotalClick", 0);
        for (k = 0; k < _Data[i].ModifierDetails.length; k++) {
          let x = _Data[i].ModifierDetails[k];
          lod.set(x, "IsCheck", false);
        }
      }
    }

    let _Data2 = this.state.SelectedItemData[0].CondimentDetails;
    //console.log('object', _Data2)
    if (_Data2 != null) {
      let i;
      let k;
      for (i = 0; i < _Data2.length; i++) {
        //  console.log("iiii--->", _Data);
        for (k = 0; k < _Data2[i].CondimentDetails.length; k++) {
          let x = _Data2[i].CondimentDetails[k];
          //  console.log("kkkk--->", x);
          lod.set(x, "IsCheck", false);
        }
      }
    }

    //console.log('New Selected',this.state.SelectedItemData[0])
    this.setState({
      Qty: 1,
      IsEdit: false,
      CondimentText: "",
      ModifierText: "",
      Remark: "",
      IsItemPopup: false,
      IsConfirmPopup: false,
    });
  };

  _GetModifierData = () => {
    let _Data = this.state.SelectedItemData[0].ModifierDetails;

    //  console.log("Root Item", this.state.SelectedItemData[0]);
    //  console.log("Qty", this.state.Qty);
    //  console.log("Get Modifier Data", _Data);

    if (_Data === null) {
      return null;
    }

    let i = 0;
    let k = 0;
    let item = [];

    for (i = 0; i < _Data.length; i++) {
      for (k = 0; k < _Data[i].ModifierDetails.length; k++) {
        let x = _Data[i].ModifierDetails[k];
        if (x.IsCheck === true) {
          lod.set(x, "Qty", this.state.Qty);

          //      console.log("Check---Yes");
          let _NewVal = RecLineValue(x);
          _NewVal.SvcRate = x.SvcRate;
          _NewVal.TaxRate = x.TaxRate;
          let val = {
            MenuCode: x.Code,
            MenuName: x.Name,
            Qty: _NewVal.Qty,
            UnitPrice: _NewVal.UnitPrice,
            Amount: _NewVal.Amount,
            SubTotal: _NewVal.SubTotal,
            TaxValue: _NewVal.TaxValue,
            SvcValue: _NewVal.SvcValue,
            TaxRate: _NewVal.TaxValue,
            SvcRate: _NewVal.SvcValue,
            Rounded: _NewVal.Amount,
            Adj: 0,
            NettAmt: _NewVal.NettAmt,
            SvcAmt: _NewVal.SvcAmt,
            TaxAmt: _NewVal.TaxAmt,
            TaxMethod: _NewVal.TaxMethod,
          };

          item.push(val);
        }
      }
    }

    //   console.log("mODIFIER iTEM", item);
    return item;
  };

  _ValidateModifier = () => {
    let _Total = 0;
    let _Selected = this.state.SelectedItemData[0];
    let k = 0;
    let i;

    let _IsCheckModifier = 0;
    let _IsCheckCondiment = 0;

    let _RefDesc = this.global.RefDesc;
    //  console.log(_RefDesc);

    // Modifier
    if (_RefDesc.substr(13, 1) === "O") {
      _IsCheckModifier = 1;
      //   console.log("Yes Modifier");
    }

    // Modifier
    if (_RefDesc.substr(14, 1) === "O") {
      //  console.log("Yes Condiment");
      _IsCheckCondiment = 1;
    }

    if (_Selected.IsCondiment === 1) {
      //   console.log("Hello");
      if (_IsCheckCondiment === 1) {
        //       console.log("Hello---1");
        let _Data = _Selected.CondimentDetails;
        //  console.log("Dating", _Data);

        if (_Data === null) {
          //   console.log("Dating---1");
          return false;
        }

        for (i = 0; i < _Data.length; i++) {
          //    console.log("iiii--->", _Data);
          for (k = 0; k < _Data[i].CondimentDetails.length; k++) {
            let x = _Data[i].CondimentDetails[k];
            //  console.log("kkkk--->", x);
            if (x.IsCheck === true) {
              _Total += 1;
              //  console.log("Selected--->", x);
            }
          }
        }

        if (_Total === 0) {
          //   console.log("Dating---2");
          swal("Please select atleast one condiment !!!");
          return false;
        }
      }
    }

    if (_Selected.IsModifier === 1) {
      //    console.log("Selected Modifier======", _IsCheckModifier);
      if (_IsCheckModifier === 1) {
        let _CheckModi = this._ValidateModifierClick();
        if (_CheckModi === false) {
          return false;
        }

        let _data2 = _Selected.ModifierDetails;

        //    console.log("Modifier object---->>>", _Selected);
        //    console.log("object---->>>", _data2);

        if (_data2 === null) {
          //  console.log('object----xxxxx', _Selected)
          return false;
        }
        _Total = 0;

        for (i = 0; i < _data2.length; i++) {
          //   console.log("iiii--->", _data2);
          for (k = 0; k < _data2[i].ModifierDetails.length; k++) {
            let x = _data2[i].ModifierDetails[k];
            //    console.log("kkkk--->", x);
            if (x.IsCheck === true) {
              _Total += 1;
              //    console.log("Selected--->", x);
            }
          }
        }

        if (_Total === 0) {
          swal("Please select atleast one modifier !!!");
          return false;
        }
      }

      return true;
    }

    return true;
  };

  _GetFixComboData = () => {
    let _Data = this.state.SelectedItemData[0].ComboSetDetails;

    //  console.log("Root Item", this.state.SelectedItemData[0]);
    //  console.log("Qty", this.state.Qty);
    //  console.log("Get Modifier Data", _Data);

    if (_Data === null) {
      return null;
    }

    let i = 0;
    let k = 0;
    let item = [];

    for (i = 0; i < _Data.length; i++) {
      let x = _Data[i];
      let val = {
        MenuCode: x.Code,
        MenuName: x.Name,
        Qty: x.Qty * this.state.Qty,
        UnitPrice: 0,
        Amount: 0,
        SubTotal: 0,
        TaxValue: 0,
        SvcValue: 0,
        TaxRate: 0,
        SvcRate: 0,
        Rounded: 0,
        Adj: 0,
        NettAmt: 0,
        SvcAmt: 0,
        TaxAmt: 0,
        //    TaxMethod: _NewVal.TaxMethod,
      };

      item.push(val);
    }

    //    console.log(item)
    return item;
  };

  _ButtonEditClick = () => {
    //      console.log('Edit Button', this.state.IsEdit)

    let _Edit = false;

    if (this.state.IsEdit === true) {
      _Edit = false;
    } else {
      _Edit = true;
    }

    this.setState({ IsLine: "full", IsEdit: _Edit });
  };

  _ConfirmCartOrder = () => {
    if (this.global.LiveItemCount === 0) {
      return null;
    }

    let data = this.global.LiveData;

    //     console.log('LiveData', data)

    if (data === null) {
      return null;
    }

    return (
      <Ion.IonModal isOpen={this.state.IsConfirmPopup} backdropDismiss={false}>
        <Ion.IonHeader>
          <Ion.IonToolbar color={this.global.Color}>
            <Ion.IonButtons slot="start">
              <Ion.IonButton
                onClick={() =>
                  this.setState({ IsEdit: false, IsConfirmPopup: false })
                }
              >
                <Ion.IonIcon icon={chevronBack}></Ion.IonIcon>
              </Ion.IonButton>
            </Ion.IonButtons>
            <Ion.IonTitle>{this.global.Title}</Ion.IonTitle>
            <Ion.IonButtons slot="end">
              <Ion.IonButton onClick={() => this._ButtonEditClick()}>
                <Ion.IonText>
                  {
                    this.global.LanguagePack[this.global.LanguageNo]
                      .ButtonEditCaption
                  }
                </Ion.IonText>
              </Ion.IonButton>
            </Ion.IonButtons>
          </Ion.IonToolbar>
        </Ion.IonHeader>
        <Ion.IonContent>
          <Ion.IonList>
            {data.map((c, index) => (
              <Ion.IonItemGroup>
                <Ion.IonItem lines={this.state.IsLine}>
                  <Ion.IonLabel style={_lineTextFont}>
                    <span>
                      {c.MenuCode} - {c.MenuName}
                    </span>
                    <span className="MyDisplayRight2">
                      {c.Qty} x {ConvertFormat(c.UnitPrice)}
                    </span>
                    <br />
                    <span></span>
                    <span className="MyDisplayRight2">
                      {ConvertFormat(c.SubTotal)}
                    </span>
                    {this._ShowRemarktData(index)}
                    {this._ShowComboSetData(index)}
                    {this._ShowCondiment(index)}
                    {this._ShowModifier(index)}
                  </Ion.IonLabel>
                </Ion.IonItem>
                {this._RenderEditQty(index)}
              </Ion.IonItemGroup>
            ))}
          </Ion.IonList>
          {this._ShowConfirmTotal()}
        </Ion.IonContent>
        <Ion.IonFooter>
          <Ion.IonButton
            color={this.global.Color}
            expand="full"
            size="large"
            onClick={() => this.setState({ IsConfirm: true })}
          >
            {
              this.global.LanguagePack[this.global.LanguageNo]
                .ButtonComfirmCaption
            }
          </Ion.IonButton>
        </Ion.IonFooter>
      </Ion.IonModal>
    );
  };

  _ShowModifier = (row) => {
    let _data = this.global.LiveData[row].ModifierData;

    //  console.log('Modifier List', _data)

    if (_data === null) {
      return null;
    }

    return (
      <Ion.IonText style={_lineFont}>
        <br />
        <Ion.IonList>
          {_data.map((c, index) => (
            <div>
              <span>{c.MenuName}</span>
              <span className="MyDisplayRight2">
                {ConvertFormat(c.SubTotal)}
              </span>
              <br />
            </div>
          ))}
        </Ion.IonList>
      </Ion.IonText>
    );
  };

  _ShowComboSetData = (row) => {
    let _data = this.global.LiveData[row].ComboSetData;

    //  console.log('Modifier List', _data)

    if (_data === null) {
      return null;
    }

    return (
      <Ion.IonText style={_lineFont}>
        <br />
        <Ion.IonList>
          {_data.map((c, index) => (
            <div>
              <span>{c.MenuName}</span>
              <span className="MyDisplayRight2"></span>
              <br />
            </div>
          ))}
        </Ion.IonList>
      </Ion.IonText>
    );
  };

  _ShowRemarktData = (row) => {
    let _data = this.global.LiveData[row];

    //   console.log("Show Remark", _data);

    if (_data === null) {
      return null;
    }

    return (
      <Ion.IonText style={_lineFont}>
        <br />
        <span>{_data.Remark}</span>
        <span className="MyDisplayRight2"></span>
      </Ion.IonText>
    );
  };

  _ShowCondiment = (row) => {
    //   console.log(this.global.LiveData[row])

    if (this.global.LiveData[row].Condiment === null) {
      return null;
    }

    return (
      <Ion.IonText color={this.global.Color}>
        <br />
        <span style={_lineTextFont}>{this.global.LiveData[row].Condiment}</span>
      </Ion.IonText>
    );
  };

  _ShowAlert = () => {
    return (
      <Ion.IonAlert
        isOpen={this.state.IsLimit}
        onDidDismiss={() => this.setState({ IsLimit: false })}
        header={"Alert"}
        message={"Over Quantity Limit !!!"}
        buttons={["OK"]}
      />
    );
  };

  _ShowConfirmTotal = () => {
    //    console.log('global',this.global)

    const _ShowDelCharge = () => {
      if (this.global.LiveDelCharge === 0) {
        return null;
      } else
        return (
          <div>
            <span>Delivery Charge</span>
            <span className="MyDisplayRight2">
              {ConvertFormat(this.global.LiveDelCharge)}
            </span>
            <br />
          </div>
        );
    };

    return (
      <Ion.IonList>
        <Ion.IonItem lines="full">
          <Ion.IonText style={_lineFont} slot="start">
            {this.global.LanguagePack[this.global.LanguageNo].LabelSubtotal}
          </Ion.IonText>
          <Ion.IonText style={_lineFont} slot="end">
            {ConvertFormat(this.global.LiveSubtotal)}
          </Ion.IonText>
        </Ion.IonItem>
        <Ion.IonItem lines="full">
          <Ion.IonLabel style={_lineFont}>
            {_ShowDelCharge()}
            <span>
              {this.global.LanguagePack[this.global.LanguageNo].LabelDiscount}
            </span>
            <span className="MyDisplayRight2">
              {ConvertFormat(this.global.LiveLineDiscount)}
            </span>
            <br />
            <span>
              {this.global.LanguagePack[this.global.LanguageNo].LabelSvc}
            </span>
            <span className="MyDisplayRight2">
              {ConvertFormat(this.global.LiveSvcValue)}
            </span>
            <br />
            <span>
              {this.global.LanguagePack[this.global.LanguageNo].LabelTax}
            </span>
            <span className="MyDisplayRight2">
              {ConvertFormat(this.global.LiveTaxValue)}
            </span>
            <br />
            <span>
              {this.global.LanguagePack[this.global.LanguageNo].LabelAdj}
            </span>
            <span className="MyDisplayRight2">
              {ConvertFormat(this.global.LiveAdj)}
            </span>
            <br />
          </Ion.IonLabel>
        </Ion.IonItem>
        <Ion.IonItem lines="none">
          <Ion.IonText style={_lineBoldFont} color="danger" slot="start">
            {this.global.LanguagePack[this.global.LanguageNo].LabelDue}
          </Ion.IonText>
          <Ion.IonText style={_lineBoldFont} color="danger" slot="end">
            RM {ConvertFormat(this.global.LiveRounded)}
          </Ion.IonText>
        </Ion.IonItem>
      </Ion.IonList>
    );
  };

  _RenderEditQty = (x) => {
    let z = x;

    if (this.state.IsEdit === false) {
      return null;
    }

    return (
      <Ion.IonItem lines="full">
        <Ion.IonGrid>
          <Ion.IonRow>
            <Ion.IonCol>
              <Ion.IonButton
                fill="none"
                onClick={() => this._UpdateConfirmQty("REMOVE", z)}
              >
                <Ion.IonIcon color="danger" icon={trashBin}></Ion.IonIcon>
              </Ion.IonButton>
            </Ion.IonCol>
            <Ion.IonCol size="3">
              <Ion.IonButton
                onClick={() => this._UpdateConfirmQty("MINUS", z)}
                color={this.global.Color}
                shape="round"
                fill="outline"
                expand="full"
              >
                -
              </Ion.IonButton>
            </Ion.IonCol>
            <Ion.IonCol size="3">
              <Ion.IonButton
                onClick={() => this._UpdateConfirmQty("ADD", z)}
                color={this.global.Color}
                shape="round"
                fill="outline"
                expand="full"
              >
                +
              </Ion.IonButton>
            </Ion.IonCol>
          </Ion.IonRow>
        </Ion.IonGrid>
      </Ion.IonItem>
    );
  };

  _UpdateConfirmQty = (zAction, x) => {
    let z = parseInt(x);
    let _NewQty = this.global.LiveData[z].Qty;

    switch (zAction) {
      case "ADD":
        if (this.global.IsNoEditQty === true) {
          return null;
        }
        _NewQty = _NewQty + 1;
        break;
      case "MINUS":
        if (this.global.IsNoEditQty === true) {
          return null;
        }
        _NewQty = _NewQty - 1;
        break;
      case "REMOVE":
        _NewQty = 0;
        break;
      default:
        break;
    }

    if (_NewQty <= 0) {
      _NewQty = 0;
    }

    let val = {
      Action: "EDIT",
      Index: x,
      Qty: _NewQty,
    };
    //       console.log('EDIT---> this global', this.global)
    Save_LiveData(val, this.global.LiveData);

    //     console.log('New', this.global.LiveData)
  };

  _RenderWaiting = () => {
    return (
      <Ion.IonAlert
        isOpen={this.state.IsLoading}
        onDidDismiss={() => this.setState({ IsLoading: false })}
        message={this.global.LanguagePack[this.global.LanguageNo].AlertWait}
      />
    );
  };

  render() {
    if (this.state.IsStart === true) {
      return <PosStart />;
    }

    //  console.log("Data", this.global.LiveData);

    if (this.state.IsSubmitPayment === true) {
      return <PosSettlement />;
    }

    if (this.state.IsShowOrder === true) {
      return <PosOrder />;
    }

    //{this._RenderErrorPopup()}
    return (
      <Ion.IonApp>
        {this._RenderHeader()}
        <Ion.IonContent>
          {this._RenderItem()}

          {this._RenderSelectedItem()}
          {this._RenderClassGroupPopup()}
          {this._PopupCondimentDetails()}
          {this._PopupModifierDetails()}
          {this._RenderRemark()}
          {this._ConfirmCartOrder()}
          {/*   {this._RenderEditAddress()} */}
          {this._RenderOutlet()}
          {this._AlertConfirm()}
          {this._AlertConfirmExit()}
          {this._ShowAlert()}
          {this._RenderWaiting()}
        </Ion.IonContent>
      </Ion.IonApp>
    );
  }
}
